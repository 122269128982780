.deal {
    .deal-hotness {
        background:#dfdfdf;
        border-radius:6px;
        box-shadow:0 1px 3px 0 rgba(0,0,0,0.12);
        display: inline-block;
        height:10px;
        left: 0;
        position: absolute;
        top: 11px;
        width:90%;
        max-width: 100px;
    }
    .deal-hotness-meter {
        border-radius:6px;
        color:rgba(0,0,0,0.87);
        font:600 8px/8px 'Open Sans', sans-serif;
        height:10px;
        letter-spacing:0.22px;
        text-align:center;
        text-shadow:0 2px 4px rgba(0,0,0,0.16);
    }
    .deal-hotness-label {
        height:10px;
        left:0;
        padding:0 5px;
        position:absolute;
        top:0;
        width:100%;
    }
}


article.flag-hotness-sizzling .deal-hotness-meter {
    background:#ff0000;
    color:#fff;
    text-align:left;
    width:100%!important;
}

article.flag-hotness-sizzling .deal-hotness-meter:before {
    content: "SIZZLING!";
    padding-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

article.flag-hotness-very_hot .deal-hotness-meter {
    background:#ff0000;
    color:#fff;
    text-align:left;
}

article.flag-hotness-very_hot .deal-hotness-meter:before {
    content: "VERY HOT";
    padding-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

article.flag-hotness-hot .deal-hotness-meter {
    background:#ff9700;
    color:#fff;
    text-align:left;
}

article.flag-hotness-hot .deal-hotness-meter:before {
    content: "HOT";
    padding-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

article.flag-hotness-warm .deal-hotness-meter {
    background:#ffea3a;
    text-align:left;
}

article.flag-hotness-warm .deal-hotness-meter:before {
    content: "WARM";
    padding-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

article.flag-hotness-cool .deal-hotness-meter {
    background:#4ec2f6;
    text-align:left;
    width:0;
}

article.flag-hotness-cool .deal-hotness-meter:before{
    content: "";
}

article.flag-offer_status-expired .deal-hotness-meter,
article.flag-offer_status-sold_out .deal-hotness-meter {
    width:100%!important;
    background: none;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
}

article.flag-offer_status-expired .deal-hotness-meter:before{
    content: "EXPIRED" !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

article.flag-offer_status-sold_out .deal-hotness-meter:before{
    content: "SOLD OUT" !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and #{breakpoint(medium)} {
    .dealbox .hotness-container .hotness {
        width: 70px;
    }
}
@media screen and #{breakpoint(large)} {
    .deal {
        .deal-hotness {
            position: relative;
            top: 0;
        }
        .deal-hotness-meter {
            font:600 10px/10px 'Open Sans', sans-serif;
        }
    }
}