.seo-box-widget {
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.40),0 0 2px 0 rgba(0,0,0,0.12);
    height: auto;
    .seo-table-head {
        color: #495d89;
        font-size: 14px;
        font-weight: 600;
        &:nth-of-type(3){
            width: 100px;
        }
    }
    .white-blue-titlebar{
        box-shadow: none;
    }
    .seo-table-body{
        tr:nth-child(even){
            background-color: #f5f6fa;
        }
        .seo-table-discount {
            color: #444444;
            font-size: 12px;
        }
        .seo-table-details {
            color: rgba(17, 0, 0, 0.87);
            font-size: 14px;
            line-height: 1.29;
        }
        .seo-table-expires{
            color: #444444;
            font-size: 12px;
            font-style: italic;
        }
    }
    
}

.seo-box-widget .seo-header.white-blue-titlebar .seo-title {
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: normal;
}

@media screen and #{breakpoint(large)} {
    .seo-box-widget .seo-table-body .seo-table-discount {
        font-size: 14px;
        font-weight: bold;
    }
}