.the-checkout {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0 32px;
    .the-checkout-title{
        color: #444;
        font-size: 16px;
        font-weight: bold;
    }
    .checkout-content{
        .blog-entry {
            color: #444;
            display: flex;
            margin-bottom: 10px;
            .blog-image {
                background-color: gray;
                height: 50px;
                margin-right: 5px;
                min-width: 50px;
            }
            .blog-title {
                font-size: 10px;
                font-weight: bold;
                margin-bottom: 0;
                .blog-title-link {
                    color: #444;
                }
            }
            .blog-time-ago {
                display: block;
                font-size: 8px;
            }
        }   
    }
}

@media screen and #{breakpoint(medium)} {
    .the-checkout {
        padding: 0;
    }

    .the-checkout .checkout-content .blog-entry .blog-time-ago {
        font-size: 9px;
    }
}

@media screen and #{breakpoint(large)} {
    .the-checkout .the-checkout-title {
        font-size: 18px;
    }

    .the-checkout .checkout-content .blog-entry .blog-image{
        height: 58px;
        width: 58px;
    }
    .the-checkout .checkout-content .blog-entry .blog-title {
        font-size: 14px;
    }

    .the-checkout .checkout-content .blog-entry .blog-time-ago {
        font-size: 12px;
    }
}