
.gift-guides-wrapper {
    background: #eee;
    padding: 5px;
}
.gift-guides-wrapper .widget-content{
  height: 230px;
}

.gift-guides-content {
    background: #fff;
    margin-top: 30px;
}


.img-rect {
    margin-top: 10px;
    position: relative;
    height: 144px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    .img-wrapper {
        position: relative;
        height: 144px;
    }

}

.img-square {
    position: relative;
    height: 210px;
    margin: 10px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    .img-wrapper {
        position: relative;
        height: 210px;
    }
}
.img-container {
   padding-right: 10px !important;

}
.img-left {
    // padding-right: 2px;
}
.img-right {
    // padding-left: 2px;
}

.img-wrapper-padding {
    position: relative;
}
.title-overlay-img {
    padding: 9px 20px 9px 8px;
    width: 100%;
    font-size: 24px;
    position: absolute;
    top: 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background: rgba(38,51,107,0.8);
    margin-left: 1px;
    box-sizing: border-box;
    line-height: 25px;
    font-family: $font-family-open-sans;
    text-transform: uppercase;
    height: 64px;
    overflow: hidden;
    transition:0.5s;
    p {
        margin-bottom: 0;
        font-size: inherit;
        line-height: 25px;
    }
}
.title-overlay-img.overflow:hover {
  height: 100%;
  transition:0.5s;
}

.title-overlay-img.overflow:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 22%;
    height: 24px;
}
.title-overlay-img.overflow:hover > .title-overlay-img.overflow:after{
  content: "";
}

.title-overlay-img-padding {
    padding: 10px;
    width: 97%;
    font-size: 24px;
    position: absolute;
    top: 0;
    text-align: center;
    color: #fff;
    font-weight: bold;
    background: rgba(39,55,105,0.8);
    margin-top: 8px;
    margin-left: 8px;
    box-sizing: border-box;
    line-height: 25px;
}

.gift-list-links {
    font-family: $font-family-open-sans-cond;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.72;
    text-align: center;
    color: #3e50b4;
    padding: 15px 5px 25px 5px;
    margin: 0;
    text-transform: uppercase;
    ul {
        margin-left: 0 !important;
        list-style-type: none;
        line-height: normal;
        line-height: 35px;
        font-size: 24px;
    }
    .title {
        font-size: 24px;
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.38;
        text-align: center;
        color: #4a4a4a;
        letter-spacing: -1px;
    }

    .more-guides a {
        font-size: 25px;
        font-weight: bold;
        font-stretch: condensed;
        letter-spacing: -1.2px;
        text-align: left;
        color: #ee6b00;
        margin-bottom: 25px;
        i {
            font-size: 16px;
            color: #ee6b00;
            padding: 8px;
        }
    }
    a {
        color: #3e50b4;
    }
}

@media screen and (min-width: 478px) {
    .gift-list-links {
        .title {
            font-size: 27px;
            font-weight: bold;
            text-align: center;
            color: #9b9b9b;
        }
        ul {
            margin-bottom: 20px;
            line-height: 44px;
        }
    }
    .top-gift-guide-categories {
        margin-top: 15px;
        padding: 0 10px;
    }
    .img-rect {
        .img-wrapper {
            height: 144px;
        }
        height: 144px;
        padding: 0;
        margin-bottom: 10px;
    }
    .img-square {
        .img-wrapper {
            height: 210px;
        }
        height: 210px;
        margin-bottom: 15px;
    }

    .img-right {
        padding-left: 0;
    }
    .title-overlay-img {
        padding: 9px 20px 9px 8px;
        margin-top: 0;
        margin-left: 0;

    }

}
@media screen and (min-width: 873px) {
    .top-gift-guide-categories {
        margin-top: 10px;
    }
    .img-rect {
        .img-wrapper {
            height: 272px;
        }
        height: 272px;
    }
    .img-square {
        .img-wrapper {
            height: 210px;
        }
        height: 210px;
        margin-bottom: 15px;
    }

    .img-right {
        padding-left: 0;
    }
    .title-overlay-img {
        margin-top: 0;
        margin-left: 0;

    }
    .gift-list-links {
        font-size: 25px;
        .title {
            font-size: 44px;
            font-weight: bold;
            font-stretch: condensed;
            line-height: 0.97;
            text-align: center;
            color: #9b9b9b;
            width: 80%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        ul {
            line-height: 45px;
        }
    }
    .img-left {
        .img-wrapper {
            height: 272px;
            .title-overlay-img-padding {
                width: 98%;
            }
        }
    }

}

@media screen and (max-width: 1024px){

  .title-overlay-img{
    height: 100%;
  }

  .title-overlay-img.overflow:after {
      content: "";
  }

}
