/*MOBILE ONLY*/
.table {
    background: #eee;
    font-family: $font-family-open-sans;
}

.table-row-content {
    border-radius: 7px;
    background-color: #fafafa;
    height: auto;
    width: 100%;
    padding: 10px;
    margin: 10px;
    i {
        color: #ee6b00;
        font-size: 20px;
        padding-right: 5px;
    }
    i.fa-location-arrow {
        font-size: 16px;
    }

    .row1 {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.14;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 8px
    }
    .row2 {
        width: 100%;
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
        color: #494a4f;
        margin-bottom: 8px;
        
        i {
            color: #9b9b9b;
            font-size:14px;
        }
    }
    .row3 {
        width: 100%;
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
        color: #494a4f;
        margin-bottom: 8px;
        .retail {
            margin-right: 16px;
        }
    }

    .row4 {
        width: 100%;
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
        color: #494a4f;
        margin-bottom: 8px;
        .date {
            margin-right: 5px;
        }
        .available {
            margin-right: 5px;
        }
        i {
            padding-right: 10px;
        }
    }
}

/*GENERAL*/

i.gray-icon {
    color: #9b9b9b;
    font-size:16px;
}
.gray-text {
    color: #9b9b9b;
    font-weight: 600;
    letter-spacing: 0.3px;
}
.orange-price {
    color: #ee6b00;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.9px;
    text-align: left;
    font-family: 'Roboto Condensed', sans-serif;    
}
.retail-title {
    text-decoration: line-through;
}
.retail-price {
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: line-through;
    color: #494a4e;
    font-size: 14px;
}
/*MEDIA QUERIES*/

@media screen and (min-width: 684px) {
    .table {
        background: #fff;
        padding: 0 10px;
        font-family: $font-family-open-sans;
    }
    .table-row-content {
        font-size: 12px;
        letter-spacing: -0.6px;
        text-align: left;
        color: #494a4e !important;
        text-align: left;
        line-height: 30px;
        background: #fff;
        a {
            color:#494a4e;
        }
        .title {
            font-size: 14px;
            font-weight: bold;
            font-stretch: condensed;
            line-height: 1.14;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            font-family: $font-family-open-sans-cond;
            padding-right: 10px !important;
        }
        .category {
            font-size: 12px;
        }
        
    }
    .medium-12.large-8.columns.title {
        padding-right: 10px;
        margin-bottom: 3px;
    }

    div.table-row-content:nth-child(4n+1) {
        border-radius: 7px;
        background-color: #eeeeee;
    }
    .sale-date {
        display: inline-block;
    
    }
    
}

@media screen and (min-width: 881px) {
    .table-row-content {
        line-height: 1.14;
    }
    .center-text {
    text-align: center;
    }
    .orange-price {
        text-align: left;
    }
    .sale-date {
        text-align: right;
        display:inline-block;
    }
    .availability {
        padding-left: 13px;
        padding-right: 0;
    }
    .time-local {
        color: #9b9b9b;
        font-size: 15px;
    }
    .available {
        padding-right: 5px;
    }
    .h-product {
        padding-left: 9px;
        padding-right: 5px;
        
    }
    .h-category {
        padding-left: 0;
        padding-right: 0;
        
    }
    .h-sale {
        padding-left: 15px;
        padding-right: 0;
        
    }
    .h-retail {
        padding-left: 7px;
        padding-right: 0;
        
    }
    .h-sale-date {
        padding-left: 0;
        padding-right: 0;
    }
    .h-store {
        padding-left: 0;
        padding-right: 0;        
    }
    .no-padding {
        padding: 0;
    }
}