.breadcrumbs{
    padding: 5px;
    margin: 0;
}
.breadcrumbs li,
.breadcrumbs li a {
    font-size: 11px;
    text-transform: capitalize !important;
}
.breadcrumbs li a {
    color: #3e50b4;
}
.breadcrumbs li:not(:last-child)::after{
    margin: 5px !important;
    content: '>' !important;
}

@media screen and #{breakpoint(large)} {
    .breadcrumbs li,
    .breadcrumbs li a {
        font: normal 14px/14px "open sans", sans-serif;
        margin-bottom: 10px;
    }
}