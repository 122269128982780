@import '../widgets/merchant-page/_top-bar.scss';
@import '../layout/breadcrumbs';
/** main content **/

body{
    background-color: #eeeeee;
}
ul{list-style-type: none;margin:0;}
body {color: #3f3f3f;font: 16px 'Open Sans', sans-serif;}
p, h2{margin:0;}

/*  About   */

.pageAbout .content-container{max-width:100%;padding:0 !important;} 
.pageAbout .column {padding:0;}
.pageAbout .row .row.collapse{max-width:100%;margin:0;}
.pageAbout #branding{margin-bottom:0;border-bottom:0px;}
.pageAbout #main{background-color: #FFF !important;}
.pageAbout #main > div{float:left;width:100%;margin: 0 auto;}
#branding .inner{display:none;}
.pageAbout #wrapper, .pageAbout #main {width:100%;padding-top: 0px!important;}  
.pageAbout .about-inner{max-width:1022px;margin: 0 auto;padding:0 5px;}
.real-description{background-image: linear-gradient(299.88deg, #8F9FDA 0%, #4A5BB9 100%);width: 100%;padding:35px 0;float:left;}
.real-description h1{color: #FEFFFE;font-size: 34px;font-weight: 700;line-height: 46px;text-align: center;width: 620px;float: right;padding-top: 25px;margin:0;}
.how-it-works{width:100%;text-align:center;margin:0 auto;padding: 55px 0;background-color:#FFFFFF;float:left;}
.how-it-works .title h2, .about-editors h2{font-size:28px;color: #000000;font-weight: 700;line-height: 34px;text-align: center;padding:5px 0 20px;}
.how-it-works .title p{color: #171817;font-size: 18px;font-weight: 300;line-height: 28px;width: 532px;margin:0 auto 40px;}
.how-it-works ul{width:100%;margin: 0 auto;}
.how-assets{color: #252525;font-size: 14px;font-weight: 300;line-height: 17px;width:100%;text-align: center;display:inline-block;}
.how-assets li{width:310px;height:300px;float:left;}
.how-assets li{margin-right:35px;margin-bottom: 30px;}
.how-assets li:nth-child(3n){margin-right:0px;}
.how-assets li h3{font-size: 18px;font-weight: 700;line-height: 22px;padding: 20px 0 11px;}
.how-assets li p{font-size: 14px;font-weight: 300;line-height: 24px;width: 310px;}
.how-assets li div{width: 100%;float:left;}
.how-assets li a{color: #FFFFFF;font-size: 15px;font-weight: 700;line-height: 18px;text-align: center; padding:11px 61px;background-color: #4A5BB9;border-radius: 4px;display:inline-block}
.about-us-description, .contact-us{padding: 55px 0;float:left;text-align:center;margin:0 auto;width:100%;background-color: #F8F8F8;}
.about-us-description .combined-shape{width:445px;margin-right:60px;float:left;padding: 100px 0 90px;}
.about-us-description .combined-shape .rectangle{
	width:400px;
	height:270px;    
	background: url(/img/about-us-section.png) no-repeat scroll 0 0 transparent;
    background-size: cover;
	float: right;
}
.about-us-description .bens-bargains-began{color: #252525;font-size: 15px;font-weight: 300;line-height: 24px;text-align: left;}
.about-us-description .bens-bargains-began h2{color: #000000;font-size: 28px;font-weight: 700;line-height: 34px;text-align: center;margin-bottom:23px;}
.about-editors{padding: 65px 5px 10px;float:left;width:100%;background: #FFF;}
.about-editors p{color: #171817;font-size: 18px;font-weight: 300;line-height: 28px;width: 700px;text-align: center;margin:0 auto 40px;}
.about-editors .editors-list{width:100%;display:flex;flex-wrap: wrap;}
.about-editors .editors-list div{margin:0 auto;padding-bottom: 15px;float:left;width: 290px;text-align: center;}
.about-editors .editors-list div:first-child {margin-left:0;}
.about-editors .editors-list div:nth-child(3n) {margin-right:0;}
.about-editors .editors-list div > img{width:190px;height:190px;border-radius:50%;margin-bottom:20px;}
.about-editors .editors-list div > h3{margin-bottom:6px;}
.about-editors .editors-list div > h3 a{color: #000000;font-family: 'Roboto', 'Lato', sans-serif;font-size: 16px;font-weight: 700;letter-spacing: 0.49px;line-height: 19px;text-align: center;}
.about-editors .editors-list div > p{color: #252525;font-size: 14px;font-weight: 300;line-height: 24px;width: 290px;text-align: center;}
.contact-us ul li{text-align:center;float:left;padding:0 54px;}
.contact-us ul li:first-child{border-right: 1px solid #DCDCDC;}
.contact-us li h2{font-size: 26px;font-weight: 700;line-height: 31px;margin-bottom:12px;}
.contact-us li p{color: #252525;font-size: 15px;font-weight: 300;line-height: 18px;width: 387px;text-align: center;padding-bottom:22px;}
.contact-us li:first-child a{display:inline-block;color: #FFFFFF;font-size: 15px;font-weight: 700;line-height: 18px;text-align: center;padding:12px 57px;background-color: #4A5BB9;border-radius: 4px;}
.contact-us li a{ border: 1px solid #4A5BB9;display:inline-block;color: #4A5BB9;font-size: 15px;font-weight: 700;line-height: 18px;text-align: center;padding:12px 36px;background-color: #FFFFFF;border-radius: 4px;}
.contact-us li div{height:44px;float: left;text-align: center;margin: 0 auto;width: 100%;}
.statistics {padding:60px 0px;float:left;width:100%;background: #FFF;}
.statistics ul{width:685px;margin: 0 auto;}
.statistics li{float:left;width: 228px;color: #181818;font-size: 14px;font-weight: 800;letter-spacing: 0.19px;line-height: 17px;text-align: center;}
.statistics label{font-size: 60px;font-weight: 400;line-height: 72px;color:#B7B7B7;}
.statistics li label span{font-weight: 800;}
.statistics li:first-child label span{color: #4A5BB9;}
.statistics li:nth-child(2) label span{color: #F07100;}
.statistics li:nth-child(3) label span{color: #4AB991;}
.pageAbout footer{margin-top: 0px;}
/* End About */
@media screen and (min-width : 945px)  {
	.wrapper{
	  display: grid;
	  grid-template-columns: repeat(12, 1fr);
	  grid-gap: 10px;
	  grid-auto-rows: minmax(100px, auto);
	  margin: 0 auto;
		width:920px;
	}	
	.real-description a {
	  grid-column: 1/5;
	  grid-row: 1;
	  text-align: center;
	}
	.real-description  h1{
		grid-column: 5/12;
		grid-row: 1;
		text-align: left;
		padding-left: 25px;
	}
}
@media screen  and (min-width : 681px) and (max-width : 945px)  {
	.wrapper{
	  display: grid;
	  grid-template-columns: repeat(12, 1fr);
	  grid-gap: 10px;
	  grid-auto-rows: minmax(100px, auto);
	  margin: 0 auto;
	}	
	.real-description a {
	  grid-column: 1/7;
	  grid-row: 1;
	  text-align: center;
	}
	.real-description  h1{
	  grid-column: 7/12;
	  grid-row: 1;
	  text-align: left;
	}
}	
@media screen and (min-width : 641px) and (max-width : 1024px) {
	.pageAbout .about-inner {width: 100%;}
	.real-description{padding:35px 0;height: auto;}
	.how-assets li{margin-right:0px;}
	.how-assets li:nth-child(odd){margin-right:75px;}
	.how-assets li{width:280px;height: 100%;margin-bottom: 58px;}
	.how-assets li p{width:280px;}
	.how-it-works ul {width:638px;}	
	.how-assets li div{width: 100%;}
	.about-us-description .about-inner > div{ padding:0px 50px 11px; margin:0 auto;}
	.about-us-description .combined-shape{width:100%;}	
	.contact-us ul {width:100%;float:left;margin: 0;}
	.contact-us ul li{padding:0; width:100%;}
	.contact-us li p{width: 387px;text-align: center;margin: 0 auto;}
        .contact-us ul li:first-child{border-right: none;}
	.about-us-description .bens-bargains-began p{font-size:15px;text-align:center;}       
	.about-us-description, .contact-us{padding: 28px 0;}
	.about-editors{padding:50px 25px;}
	.about-editors p{width: 100%;}
	.about-editors ul{width:660px;margin: 0 auto;}
	.about-editors .editors-list div:nth-child(even) {
	    margin-right: 0px;
	}
	.about-us-description .combined-shape .rectangle{float: none;margin: 55px auto 25px;}
        .about-us-description .bens-bargains-began h2{position:absolute;width:100%;left: 0;}
        .pageAbout .about-inner{padding: 0;}
        
}	
@media screen and (min-width : 641px) and (max-width : 945px) {
	.real-description h1 {
	    margin-left: 0;
            padding-top:0px;
	    width: 50%;    
		padding-top: 0px;
		min-width:323px;
	}	
	.about-editors .editors-list {
	    width: 100%;
	    text-align: center;
	    margin: 0 auto;
	}
	.about-editors .editors-list div{margin: 0 auto !important;}
	.statistics li{width:33%}
	.statistics ul{width:100%}
	.real-description a{padding-top: 20px;}      
}
@media screen and (min-width : 320px) and (max-width : 680px) {
	.real-description{padding: 18px 6px 28px;}
	.branding{padding:0;text-align: center;}
	.real-description h1{width: 100%;margin-top: 10px;font-size: 28px;}
	.real-description a{float:none;display: inline-block;}
	 .real-description h1{padding-top: 25px;}
        .about-editors p{font-size: 16px;}
	body.pageAbout{font-size:16px;}
	.pageAbout .about-inner {width: 100%;}	
	.how-assets{width:100%;}
	.how-assets li{height: 100%;margin: 20px 0px;width:100%;}
	.how-assets li p{width:100%;}
	.how-it-works{padding:30px 20px;}
	.how-it-works .title p{width:100%;}
	.how-it-works ul {width:100%;}	
	.how-assets li div{width: 100%;}
	.about-us-description .about-inner > div{ padding:0px; margin:0 auto;text-align:center;}
	.about-us-description .bens-bargains-began h2{position:absolute;width:100%}
	.about-us-description .combined-shape{width:100%;padding: 55px 20px 25px!important;}	
	.about-us-description .combined-shape .rectangle{width:100%;max-width: 400px;max-height: 270px;float: none;margin: 0 auto;background-size: contain;background-position: center;}
	.contact-us ul {width:100%;float:left;}
	.contact-us ul li{padding:0px 20px; width:100%;}
	.contact-us li p{width:auto;line-height:24px;font-size: 15px;}
	.about-us-description .bens-bargains-began p{font-size: 14px;text-align: center;font-weight: 300;line-height: 26px;padding:25px;}
	.about-us-description, .contact-us{padding: 28px 0;}
	.about-editors{padding:30px 20px;}
	.about-editors p, .about-editors .editors-list div>p, .about-editors .editors-list div{width: 100%;}
	.about-editors ul{width:100%;margin: 0 auto;}
	.about-editors .editors-list div p{margin-bottom:0px;}
	.about-editors .editors-list div{margin-right:0px;padding-bottom: 20px;}
	.about-editors .editors-list div:nth-child(even) {
	    padding-right: 0px;
	}	
	.statistics{padding:25px 0;}
	.statistics ul, .statistics li{width:100%}
	.statistics li{padding: 25px 0;}
	.real-description a{padding-top:0;}
        .branding{width: 300px;margin: 0 auto;}
        .how-assets li img{width:95px;height:95px;}
        .contact-us li h2{font-size: 22px;}
}
@media screen and (max-width : 360px) {
	.about-us-description .combined-shape .rectangle{height: 210px;}
        
}

