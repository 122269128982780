.latest-deals {
    color: #444;
    height: auto;
    padding: 0 32px;
    .latest-deals-title{
        font-size: 16px;
        font-weight: bold;
    }
    .latest-offer{
        margin-bottom: 8px;
        .offer-title {
            font-weight: bold;
            font-size: 10px;
            margin-bottom: 0 !important;
            .offer-price {
                color: #ee6b00;
            }
            .offer-title-link {
                color: #444;
            }
            .offer-merchant {
                color: #3e50b4;
            }
        }
        .time-ago {
            display: block;
            font-size: 9px;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .latest-deals {
        padding: 0;
    }
    .latest-deals .latest-offer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .latest-deals .offer-title {
        margin: 0;
    }

    .latest-deals .time-ago {
        font-size: 9px;
    }
}

@media screen and #{breakpoint(large)} {
    .latest-deals .latest-offer .latest-deals-title {
        font-size: 18px;
    }

    .latest-deals .latest-offer .offer-title {
        font-size: 14px;
    }

    .latest-deals .latest-offer .time-ago {
        font-size: 12px;
    }
}
@media screen and (max-width: 767px){
.bens-announcements-inner {
    width: 100%;
    display: inline-block;
}
}