@import "dealbox.scss";
@import "paginator.scss";

.offers-widget {
    height: auto;
}

.offers-list {
    padding: 15px;
}

.filters {
    display: flex;
    margin-bottom: 19px;
    position: relative;
}

.filters a.hottest,
.filters a.newest{
    background-color: #fff;
    border: solid #495d88 1px;
    color: #495d88;
    display: inline-block;
    font-size: 14px;
    height: 29px;
    line-height: 29px;
    min-width: 100px;
    position: relative;
    text-align: center;
    &.active{
        background-color: #495d88;
        color: #fff;
        font-weight: bold;
        &:after{
            content: "";
            border-left: solid transparent 7px;
            border-right: solid transparent 7px;
            border-top: solid #495d88 10px;
            height: 0;
            position: absolute;
            right: 40px;
            bottom: -10px;
            width: 0;
        }
    }
}

.filters .expired {
    margin-left: 5px ;
    margin-right: 0 !important;
    position: absolute;
    right: 0;
    .fancy-checkbox{
        font-size: 12px;
        font-stretch: condensed;
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        margin: 0;
    }
    input[type="checkbox"]{
        display: none;
    }
    input[type=checkbox] + label:before{
        font-family: FontAwesome;
        display: inline-block;
        content: "\f0c8";
        letter-spacing: 5px;
        color: #33a8ff;
    }
    input[type=checkbox]:checked + label:before{
        content: "\f14a";
        letter-spacing: 5px;
    }
}

@media screen and #{breakpoint(large)} {
    .no-deals-l1 {
        color: #374B75;
        font: bold 20px/20px "Open Sans", sans-serif;
        padding-bottom: 10px;
        text-align: center;
    }

    .no-deals-l2 {
        font: bold 16px/16px "Open Sans", sans-serif;
        text-align: center;
    }

    .offers-list {
        padding: 15px 0;
    }
}
@media screen and (max-width: 368px) {
    .filters .expired{
        right:auto;
    }
}
