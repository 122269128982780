.header-title {
    font-family: 'Open Sans';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -1.5px;
    color: #ffffff;
}

.header-subtitle {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.9px;
    color: #ff9700;
    display: inline-block;
}


.header-hub {
    background-image: none;
    background: #26336b;
    margin: 11px 0;
}
.hub-header {
    background-image: none;
    line-height: 19px;
    padding: 11px;
}




@media screen and (min-width: 548px) {
    .header-hub {
/*        background-image: url(/home/crodriguez/Desktop/InternetBrands/BensBargains/headerBanner.png);*/
        background-repeat: no-repeat;
        background-size: 411px 66px;
        background-position: top right;
        height: 66px;
        margin-bottom: 15px;
    }
    .hub-header {
        height: 66px;
        background-image: linear-gradient(to right, #26336b 50%, rgba(83,104,171,0.1), rgba(78,98,164,0));
        line-height: 66px;
        padding: 0;
    }
    .header-subtitle {
        vertical-align: top;
        margin-left: 20px;
    }
}

@media screen and (min-width: 873px) {
    .hub-header {
        background-image: linear-gradient(to right, #26336b 49%, rgba(83,104,171,0.1), rgba(78,98,164,0));
        padding: 0;
    }
}

@media screen and (min-width: 800px) {
    .hub-header {
        background-image: linear-gradient(to right, #26336b 59%, rgba(83,104,171,0.1), rgba(78,98,164,0));
    }
}
@media screen and (min-width: 1050px) {
    .hub-header {
        background-image: linear-gradient(to right, #26336b 66%, rgba(83,104,171,0.1), rgba(78,98,164,0));
    }
}
.widget-content {
    padding: 0 10px;
}