@media screen and #{breakpoint(large)} {
    .stickyad-wrapp {
            height: 653px;
        }
        
    .stickyad {
        height: 653px;
        width: 300px;
        position: relative;
        margin-top: 53px;
    }

    .stickyad div {
        position: absolute;
        top: 0;
    }

    .sticky {
        position: fixed !important;
        top: -1px;
        bottom: initial;
    }

    .stickyEnd {
        top: inherit;
        bottom: 360px;
    }

    .stickyEn {
        top: inherit;
        bottom: 460px;
    }
}