
article {
    .flag-offer_status-expired .deal-price,
    .flag-offer_status-sold_out .deal-price,{
        color:#b6b6b6 !important;
    }

    .flag-offer_status-expired .offer-badge,
    .flag-offer_status-sold_out .offer-badge,
    .flag-offer_status-expired .deal-history,
    .flag-offer_status-sold_out .deal-history {
        display: none !important;
    }

    .flag-offer_status-expired .deal-status-legend,
    .flag-offer_status-sold_out .deal-status-legend {
        color:rgba(0,0,0,0.54);
        font:italic 400 12px/12px 'Open Sans', sans-serif;
    }

    .flag-offer_status-expired .deal-status-legend a,
    .flag-offer_status-sold_out .deal-status-legend a {
        display: none;
    }
}

.dealbox {
    background-color: #ffffff;
    border-bottom: solid rgba(0, 0, 0, 0.38) 1px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 5fr 2fr;
    -ms-grid-rows: 5fr 2fr;
    height: 80px;
    padding: 5px;
    position: relative;
    .redirect-wrapper {
        display: block;
        height: 100%;
        outline: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
    }
    .header-section {
        display: none;
    }
    .left-section {
        display: flex;
        justify-content: center;
        grid-column: 1 / 4;
        grid-column-start: 1;
        -ms-grid-column-span: 3;
        grid-row: 1 / 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        .offer-img {
            background: transparent;
            height: 45px;
            letter-spacing: -0.2px;
            line-height: 0.89;
            min-width: 48px;
            font-weight: 600;
            .offer-badge {
                bottom: 20px;
                font-size: 9px;
                height: 17px;
                line-height: 17px;
                left: -6px;
                padding-left: 3px;
                position: absolute;
                width: 70px;
                @include breakpoint(large){
                    bottom: 55px;
                    font: 700 12px/20px 'Open Sans', sans-serif;
                    height: 20px;
                    line-height: 20px;
                    width: 95px;
                }
            }
            .offer-img-link {
                align-content: center;
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: center;
                img {
                    max-height: 45px;
                }
            }
        }
    }
    .hotness-container {
        align-items: flex-end;
        display: flex;
        grid-column: 1 / 4;
        grid-column-start: 1;
        -ms-grid-column-span: 3;
        grid-row: 2 / 3;
        justify-content: center;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        position: relative;
        .hotness {
            bottom: 0;
            background-color: #eeeeee;
            border-radius: 1px;
            font-size: 8px;
            font-weight: bold;
            height: 10px;
            left: 50%;
            line-height: 10px;
            min-width: 48px;
            position: absolute;
            text-align: center;
            transform: translateX(-50%);
            .hotness-meter {
                height: 10px;
            }
        }
    }
    .center-section {
        grid-column: 4 / 11;
        grid-column-start: 4;
        -ms-grid-column-span: 7;
        grid-row: 1 / 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        .offer-title {
            font-size: 10px;
            color: #000;
            .price-mobile {
                color: #ee6b00
            }
        }
        .price {
            color: #ee6b00;
        }
        a {
            color: #4a4a4a;
        }
        .offer-description {
            display: none;
        }
    }
    .footer-section {
        color: #444444;
        font-size: 10px;
        grid-column: 4 / 11;
        grid-column-start: 4;
        -ms-grid-column-span: 7;
        grid-row: 2 / 3;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        position: relative;
        .posted-by {
            position: absolute;
            bottom: -4px;
            margin: 0;
        }
    }
    .right-section {
        grid-column: 11 / 13;
        grid-column-start: 11;
        -ms-grid-column-span: 13;
        grid-row: 1 / 3;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        position: relative;
        a {
            display: block !important;
            height: 100%;
            line-height: 64px;
            text-align: center;
            width: 100%;
            svg {
                color: #3395dc;
                font-size: 25px;
            }
        }
        .code-button,
        .offer-button {
            display: none;
        }
        .offer-button {
            height: 35px !important;
            line-height: 24px !important;
        }
        .mobile-comments {
            color: #424242;
            font-size: 14px;
            font-style: normal;
            height: 0;
            left: 50%;
            position: relative;
            top: -27px;
            transform: translateX(-50%);
            .comments-bubble {
                background-color: #cad0dd;
                border-radius: 2px;
                display: inline-block;
                height: 11px;
                width: 12px;
                &:after {
                    left: 1px;
                    top: 11px;
                    @include css-triangle(#cad0dd, up-right, 2px, relative, false);
                }
            }
        }
        .deal-updated-price-responsive {
            font-size: 10px;
            font-weight: normal;
            font-style: normal;
            display: inline;
            margin-left: 20px;
            cursor: pointer;
            position: absolute;
            bottom: 19px;
        }
        .deal-updated-price-tooltip-responsive {
            background-color: #F7F5C4;
            border: 1px solid #ECD45B;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            width: 300px;
            margin-left: 100px;
            bottom: -6px;
            color: #6D7278;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            text-align: center;
            z-index: 2;
            position: absolute;
            padding: 5px;
        }
        .deal-amazon-used-price-responsive {
            margin-left: 20px;
            margin-top: 4px;
            color: #757575;
            font: 600 12px/1.456em 'Open Sans', sans-serif;
        }
    }
}

/** Hotness Classes **/

.sizzling {
    background-color: #ff0000;
    width: 100%;
    &:after {
        color: #fff;
        content: "SIZZLING";
        font-weight: bold;
        font-size: 8px;
        line-height: 11px;
    }
}

/** Hotness Styles **/
@import '../_hotness.scss';

/** Badge Classes **/
@import '../_badges.scss';

@media screen and #{breakpoint(medium)} {
    .dealbox .left-section .offer-img {
        height: 45px;
        width: 70px;
    }
}

@media screen and #{breakpoint(large)} {
    @import '_price-history-dialog.scss';
    @import '_deal-report-error.scss';
    article.flag-internal_flags-price_history .deal-history {
        display: block !important;
    }
    article {
        .flag-offer_status-expired .deal-status-legend:before{
            content: "Deal has expired";
        }
        .flag-offer_status-sold_out .deal-status-legend:before{
            content: "Deal is sold out";
        }
        .flag-offer_status-expired,
        .flag-offer_status-sold_out{
            .offer-button,
            .code-button{
                display: none !important;
            }
        }
    }

    .flag-offer_status-expired .deal-status-legend,
    .flag-offer_status-sold_out .deal-status-legend {
        padding-left: 20px;
    }

    .dealbox {
        border: none;
        border-radius: 2px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        cursor: auto;
        height: auto;
        grid-template-columns: 1.1fr 0.75fr 0.75fr 1fr 1fr 1fr 1fr 1fr 1fr 1.1fr 1.1fr 1fr;
        -ms-grid-columns: 1.1fr 0.75fr 0.75fr 1fr 1fr 1fr 1fr 1fr 1fr 1.1fr 1.1fr 1fr;
        grid-template-rows: 1.6fr 1fr auto 1.3fr;
        -ms-grid-rows: 1.6fr 1fr auto 1.3fr;
        margin-bottom: 15px;
        min-height: 250px;
        padding: 0;
        width: 100%;
        .redirect-wrapper {
            z-index: -1;
        }
        .offer-title-container {
            align-items: center;
            background-color: #f5f6fa;
            display: flex;
            grid-column: 1 / 10;
            grid-column-start: 1;
            -ms-grid-column-span: 9;
            grid-row: 1 / 2;
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            padding: 13px 0 13px 10px;
            .offer-title{
                color: #495d89;
                font: bold 16px/20px "Open sans",sans-serif;
                margin: 0;
                .title-merchant {
                    color: #3e50b4;
                }
            }
        }
        .offer-price-container {
            align-items: center;
            align-content: baseline;
            background-color: #f5f6fa;
            display: flex;
            flex-wrap: wrap;
            grid-column: 10 / 13;
            grid-column-start: 10;
            -ms-grid-column-span: 3;
            grid-row: 1 / 2;
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            padding: 13px 10px 13px 10px;
            .deal-msrp {
                color: rgba(0, 0, 0, 0.54);
                font: normal 12px/12px "Open sans", sans-serif;
                margin-left: 10px;
            }
            .deal-price {
                color: #ee6b00;
                font: 600 24px/24px "open sans", sans-serif;
                margin-right: auto;
                padding-left: 5px;
                width: auto;
            }
        }
        .comments-section {
            display: block;
            grid-column: 1 / 10;
            grid-column-start: 1;
            -ms-grid-column-span: 9;
            grid-row: 2 / 3;
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            .comments-wrapper {
                border-bottom: solid rgba(0, 0, 0, 0.12) 2px;
                height: 100%;
                width: 100%;
                margin: 0 17px 0 7px;
                height: 35px;
                .deal-comments {
                    .deal-comments-replies {
                        background-color: #cad0dc;
                        color: #495d89;
                        display: inline-block;
                        font: 600 10px/16px "Open Sans",sans-serif;
                        margin: 2px 0 2px 10px;
                        padding: 0 7px;
                        position: relative;
                        width: auto;
                        &:after {
                            @include css-triangle(#cad0dc, up-right, 2px, absolute, false);
                            right: 0;
                        }
                        &.deal-comment-has-replies {
                            background-color: #000;
                            color: #fff;
                            &:after {
                                @include css-triangle(#000, up-right, 2px, absolute, false);
                                right: 0;
                            }
                        }
                    }
                    .deal-comment-legend {
                        color: #444;
                        font: 600 10px/16px "Open Sans",sans-serif
                    }
                    &:hover{
                        .deal-comments-replies {
                            background-color: #848587;
                            color: #fff;
                            &:after {
                                @include css-triangle(#848587, up-right, 2px, absolute, false);
                                right: 0;
                            }
                        }
                    }
                }
                .deal-report-error-legend,
                .deal-report-error-reported {
                    color: #444;
                    font-size: 10px;
                    font-style: normal;
                    &:before {
                        font-family: FontAwesome;
                        content: "\f071";
                        position: relative;
                        left: -3px;
                    }
                }
            }
        }
        .deal-more-details {
            color: #03c;
        }
        .deal-more-details:hover {
            color: #03c;
            border-bottom: 1px rgba(62,79,179,0.27);
            transition: all 0.5s ease;
            text-decoration: underline;
            cursor: pointer;
        }
        .deal-alert {
            align-items: center;
            color: #444;
            display: flex;
            font: bold 10px/10px "Open Sans",sans-serif;
            grid-column: 10 / 13;
            grid-column-start: 10;
            -ms-grid-column-span: 3;
            grid-row: 2 / 3;
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            margin: 0 auto;
            width: 100%;
            padding-left: 20px;
            // &:before {
            //     color: #999;
            //     font-family: FontAwesome;
            //     font-weight: bold;
            //     line-height: 15px;
            //     margin: 5px;
            //     @include pseudo(inline-block, relative, "\f0f3");
            // }
            svg {
                color: #999;
                line-height: 15px;
                padding-right: 3px;
                font-size: 15px;
            }
            &:hover {
                color: #232d46;
                svg {
                    color: #569;
                }
            }
            &.active svg {
                color: #569;
                line-height: 15px;
                padding-right: 3px;
            }
        }
        .left-section {
            grid-row: 3 / 4;
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            .offer-img {
                height: 100px;
                margin-top: 10px;
                width: 115px;
                img {
                    max-height: 100px!important;
                    max-width: 115px;
                }
            }
        }
        .hotness-container {
            align-items: center;
            grid-row: 4 / 5;
            -ms-grid-row: 4;
            -ms-grid-row-span: 1;
            .hotness {
                border-radius: 40px;
                top: 0;
                width: 100px;
                .hotness-meter {
                    border-radius: 40px;
                }
            }
        }
        .center-section {
            grid-column: 4 / 10;
            grid-column-start: 4;
            -ms-grid-column-span: 6;
            grid-row: 3 / 4;
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            .offer-title {
                display: none;
            }
            .offer-description, p {
                color: rgba(17, 0, 0, 0.87);
                display: block;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                letter-spacing: normal;
                line-height: 1.456em;
                margin: 10px 0 0 0;
                padding: 0;
                a {
                    color: rgb(62, 80, 180);
                }
            }
        }
        .footer-section {
            align-items: center;
            display: flex;
            grid-column: 4 / 10;
            grid-column-start: 4;
            -ms-grid-column-span: 6;
            grid-row: 4 / 5;
            -ms-grid-row: 4;
            -ms-grid-row-span: 1;
            .posted-by {
                top: 0;
                bottom: auto;
            }
        }
        .right-section {
            grid-column: 10 / 13;
            grid-column-start: 10;
            -ms-grid-column-span: 3;
            grid-row: 3 / 5;
            -ms-grid-row: 3;
            -ms-grid-row-span: 2;
            .code-button,
            .offer-button {
                @include code-button;
            }
            .deal-history {
                display: none;
                margin-top: 10px;
                position: relative;
                .deal-price-history {
                    color: #4a4a4a;
                    display: block;
                    font-family: $font-family-open-sans;
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.7px;
                    line-height: 25px;
                    &:before {
                        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0IxRjlDN0VCRUFFMTFFNDk5NzhENDYwRTM3M0NBNUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0IxRjlDN0ZCRUFFMTFFNDk5NzhENDYwRTM3M0NBNUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozQjFGOUM3Q0JFQUUxMUU0OTk3OEQ0NjBFMzczQ0E1RCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozQjFGOUM3REJFQUUxMUU0OTk3OEQ0NjBFMzczQ0E1RCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl0uh44AAAAsSURBVHjaYmTADbrQ+GXYFDExUAiGgQEsZOjpGnxeICq+h3E0jhrAwAAQYACdxQKugckiagAAAABJRU5ErkJggg==) no-repeat;
                        height: 16px;
                        width: 20px;
                        @include pseudo(inline-block, relative, "");
                    }
                    &.deal-history-positive {
                        &:before {
                            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDVGQjI4NTdDMTI5MTFFNEJCNTZBNkNEQTY1ODcxQjIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDVGQjI4NThDMTI5MTFFNEJCNTZBNkNEQTY1ODcxQjIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NUZCMjg1NUMxMjkxMUU0QkI1NkE2Q0RBNjU4NzFCMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NUZCMjg1NkMxMjkxMUU0QkI1NkE2Q0RBNjU4NzFCMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Ptpe2f8AAAA0SURBVHjaYmTAAbiWOfxH5n+LOsCITR0TA4VgGBjAQqoG9MAdBF4gNr6HcTSOGsDAABBgAP3dChOLGHZ8AAAAAElFTkSuQmCC) no-repeat;
                            top: 1px;
                        }
                    }
                    .price-change-label {
                        &:after {
                            background: hsla(0, 0%, 20%, 0.7);
                            border-radius: 2px;
                            bottom: -15px;
                            color: #fff;
                            content: 'See price history';
                            font: 10px 'Open Sans', sans-serif;
                            left: -15px;
                            line-height: 1.2;
                            letter-spacing: 0.15px;
                            padding: 4px 7px;
                            position: absolute;
                            visibility: hidden;
                        }
                        &:hover {
                            &:after {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .deal-other-stores {
                .deal-amazon {
                    margin-top: 7px;
                    position: relative;
                    a{
                        color: #3e50b4;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: normal;
                        padding-left: 75px;
                        &:before {
                            background: url(../../../img/amazon-logo.png) no-repeat;
                            height: 15px;
                            left: 20px;
                            top: 4px;
                            width: 50px;
                            @include pseudo(block, absolute, "");
                        }

                    }

                }
            }
            a {
                line-height: 35px;
                height: auto;
                outline: none;
                padding-left: 20px;
                text-align: left;
            }
            svg {
                display: none;
            }
        }
        .footer-section {
            .posted-by {
                position: relative;
            }
        }
    }
}