.merchant-info {
    height: auto;
    min-height: 50px;
    padding: 0 15px;
    .merchant-description {
        color: #2d2d2d;
        font-size: 14px;
        line-height: 1.14;
        max-width: 100%;
    
        .long-desc {
            display: none;
        }

        .show-more-desc,
        .show-less-desc {
            color: #2d2d2d;
            font-weight: bold;
        }
    }
}

.merchant-logo {
    align-items: center;
    display: flex;
    float: left;
    min-height: 64px;
    min-width: 147px;
    padding-right: 20px;
}

@media screen and #{breakpoint(medium)} {
    .merchant-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        .merchant-description {
            .long-desc {
                display: inline;
            }
        }
        .show-more-desc {
            display: none;
        }
    }

    .merchant-logo {
        float: none;
        // max-height: 95px;
        // max-width: 185px;
        margin-bottom: 16px;
    }
}