.ad-scans-content {
    padding: 5px 5px;
    background: #fff;
}
.bar-link {
    cursor: pointer;
    letter-spacing: -0.9px;
}

// .img-merchant-wrapper {
//     box-shadow: 2px 3px 7px black;
//     margin: 0 10px;
//     display: flex;
//     align-content: center;
//     height: 117px;
//     width: 117px;
// }
// .merchant-img {
//     max-height: 100%;
//     max-width: 100%;
//     height: auto;
//     width: auto;
//     padding: 0;
// }