$font-family-open-sans-cond : 'Open Sans Condensed', sans-serif;
$font-family-open-sans : 'Open Sans';
html {
    overflow-x: hidden;
}
ul, dl, li {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.content-container {
    max-width: 980px;
    padding: 17px !important;
}
.off-canvas.position-left {
    top: 0px;
    background:#4a4a4a;
    left:-100%;
    width:100%;
    z-index:99999;
}
.overlay-main-section{
    top:50px;
    background-color: #000000;
    border: 1px solid #979797;
    opacity: 0.5081612723214286;
    width: 100%;
    height: 0px;
    z-index:9999;
    position: absolute;
}
/*footer {
    background: #4A4A4A;
    position: relative;
    margin-top: 30px;
    font-size: 13px;
    line-height: 1.3;
    border-top: 7px solid #cedded;
}
.footer-wrapper {
    max-width: 980px;
}*/

/*** START FOOTER ***/
/*#main-footer { clear: both; background:#4A4A4A; width:100%; border-top:0px;border-bottom: 1px #4a4a4a;overflow: hidden; }
#main-footer {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.37px;
    line-height: 14px;
    text-align: left;
    .columns{
        padding:0;
    }
    .list-info {
        li{
            padding: 6px 0 2px;

            a, a:hover{
                color: #FFFFFF;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.37px;
                line-height: 12px;
            }
        }

    }
    .list-info a:hover{text-decoration: underline;}

    h3 {
      font-family: "Open Sans Condensed",sans-serif;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.55px;
      line-height: 22px;
      text-align: left;
    }
    .footer-wrapper{
        padding:18px 0px 11px;
        > div{
            padding: 0 45px 10px;
        }
    }
    .copy{
        text-align: center;
        margin: 0 auto;
        margin-top: 15px
    }
}
#main-footer .top-logos{
    border-bottom: 1px solid #979797;
    margin-bottom: 19px;

    .media-logo{
        display: inline-block;
        text-align: center;
        padding: 7px;
        .oval{
            border-radius: 100%;
            width: 50px;
            height: 50px;
            display: inline-block;
            text-align: center;
            margin-right:26px;
        }
        a.oval{
            color:#000;
            background-color: #FFFFFF;
            font-size: 28px;
            padding: 11px;
            font-weight: bold;
        }
        a.oval:hover{
            color:#FFF;
            background-color: #000;
        }
        a.oval:hover{
            color:#FFF;
            background-color: #000;
        }
    }
    > div{
        display: inline-block;
        float: left;
    }
    .app-logos{
        padding-top: 12px;
        text-align: right;
        a{
            display: inline-block;
            background-size: contain!important;
        }
        .ios-app{
            background: url(/img/ios_app.png) no-repeat scroll 0 0 transparent;
            width: 113px;
            height: 35px;
            margin-right: 5px;
        }
        .android-app{
            background: url(/img/android_app.png) no-repeat scroll -2px 2px transparent;
            width: 120px;
            height: 40px;
            display: inline-block;
        }
    }
}
#main-footer  .add-logo {
    background: url(/img/footer-logo-withe.png) no-repeat scroll 0 0 transparent;
    background-size: contain;
    width: 151px;
    height: 66px;
    display:inline-block;
    text-indent: -9999px;
}
.iblogounify_div {
    background: #cedded;
    width: 100%;
    padding: 10px;
    img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 150px;
    }
}*/
/*a.back-to-top{
	display:none;
	position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 100;
	background-color: #C9C9C9;
	border-radius: 100%;
	opacity: 0.2178571428571429;
    height: 60px;
    width: 60px;
	text-align: center;
	color: #C4C4C4;
}
.back-to-top i{width: 2px;height: 28px;border-style: solid;background-color: #000;padding-top:15px;display:inline-block;}
.back-to-top .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
	color:#000;
}*/

/*** END FOOTER ***/
@media screen and (min-width: 1024px){
    .overlay-main-section{
        display:none;
    }
}
@media screen and (max-width: 880px)and (min-width: 640px)  {
/*#main-footer .footer-wrapper>div {
    padding: 0 10px 10px 25px;
}*/
.top-logos{
    .medium-4{
        width: 29%;
    }
    .app-logos{
        width: 36%;
    }
}
    .pageMerchant footer{
    box-shadow: 0px 1px #4a4a4a;
}
}
@media screen and (max-width: 768px) and (min-width: 689px) {
.top-logos{
    .medium-4{
        width: 31%;
    }
    .app-logos{
        width: 38%;
    }
}
.top-logos{
    .app-logos a{
        width: 48%!important;
        background-size: contain !important;
    }
}
}
@media screen and (max-width: 689px) and (min-width: 640px) {

.top-logos{
    .app-logos {
        width: 242px!important;
    }
}

}
@media screen and (max-width: 639px){
/*#main-footer .footer-wrapper>div {
    padding: 0 15px 10px;
}
#main-footer .top-logos .app-logos{text-align: center;}*/
.list-info .small-6:last-child{
    float:left;
    bottom: 60px;
    position: absolute;
}
.list-info .about{float:right;width: 125px;}
/*#main-footer .top-logos .media-logo .oval{margin-right:10px;}
#main-footer .top-logos .media-logo .oval:last-child{margin-right:0px;}
#main-footer .top-logos .media-logo{text-align: right;}
#main-footer .top-logos .app-logos .ios-app{margin-right: 15px;}
    .pageMerchant footer{
    box-shadow: 0px 1px #4a4a4a;
}*/
}
.ellipsis {text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
