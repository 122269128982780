body.pageAdScansNew {
    background: #fff;
}

.ad-scans-page{}
/* butons */
.ad-scan-buttons{
  margin-top: 10px;
}

/* i{
    padding: 0px 10px;
}*/

.ad-scan-btn-home,
.ad-scan-btn-home:hover {
    background: transparent;
    font-weight: bold;
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);*/
    font-family: "Open Sans Condensed",sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    text-align: center;
    color: #000000;
    padding: 0;
}

.back-to-ad-scan-page {
  width: 46px;
  height: 46px;
  color: #fff;
  border-radius: 23px;
  background-color: #ff9700;
  font-size: 20px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 2.3 !important;
  margin-right: 5px;
}

.ad-scan-btn-home:hover .back-to-ad-scan-page {
  background-color: #ff9700;
  color: #000;
}

.ad-scan-btn-home:active .back-to-ad-scan-page {
    background-color: #ee6b00;
}

.ad-scan-next-merchant-btn,
.ad-scan-next-merchant-btn:hover{
  background: #fff;
  font-weight: bold;
  color: #3e50b4;
  border: solid 2px #3e50b4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.ad-scan-next-merchant-btn span{
  color: #000;
}

.ad-scan-next-page-btn {
  background-color: #ffffff;
  font-family: "Open Sans Condensed",sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 0.2px;
  color: #26336b;
  padding: 0.85em 0;
  float: left;
}

.ad-scan-next-page-btn:hover {
    background-color: #eeeeee;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #3e50b4;
}

.ad-scan-next-page-btn:active {
    color: #fff;
    background-color: #3e50b4;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.ad-scan-next-page-btn.disabled,
.ad-scan-next-page-btn.disabled:hover,
.ad-scan-next-page-btn.disabled:focus,
.ad-scan-next-page-btn[disabled],
.ad-scan-next-page-btn[disabled]:hover,
.ad-scan-next-page-btn[disabled]:focus {
    background-color: #dfdfdf;
    box-shadow: none;
    color: #757575;
}

.ad-scan-other-store img{
  width: 100%;
}

@media screen and (max-width: 769px) {
    .ad-scan-next-page-btn {
        border: solid 2px #3e50b4;
    }

    .ad-scan-next-page-btn:hover {
        color: #fff;
        background-color: #3e50b4;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .ad-scan-next-page-btn.disabled,
    .ad-scan-next-page-btn.disabled:hover,
    .ad-scan-next-page-btn.disabled:focus,
    .ad-scan-next-page-btn[disabled],
    .ad-scan-next-page-btn[disabled]:hover,
    .ad-scan-next-page-btn[disabled]:focus {
        border: none;
    }

    .ad-scan-btn-home {
        color: #000;
        background: #ff9700;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .ad-scan-btn-home:active {
        background-color: #ee6b00;
        color: #fff;
    }

    .ad-scan-btn-home:hover {
        background-color: #ee6b00;
        color: #fff;
    }

    .back-to-ad-scan-page {
        background-color: transparent;
        color: #000;
        box-shadow: none;
    }

    .ad-scan-btn-home:active .back-to-ad-scan-page {
        background-color: transparent;
        color: #fff;
    }

    .ad-scan-btn-home:hover .back-to-ad-scan-page {
        background-color: transparent;
        color: #fff;
    }
}

.ad-scan-section {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 -1px 2px 0 rgba(0, 0, 0, 0.27);
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  font-size: 18px;
  color: #3e50b4;
  font-weight: bold;

}
.ad-scan-section.collapse-title {
    padding: 5px 10px;
    line-height: 36px;
}
.ad-scans-subtitle {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: 0.7px;
  text-align: left;
  color: #9b9b9b;
  padding: 18px 10px 12px 10px;
}

.ad-scan-section .clearfix{
  text-align: right;
}

.ad-scan-section .clearfix a{
  font-size: 14px;
  color: #26336b;
  text-decoration: underline;
}
.ad-scan-section .clearfix a.collapse-button {
    text-decoration: none;
}
.ad-scan-section i.fa-caret-up,
.ad-scan-section i.fa-caret-down {
    font-size: 36px;
    color: #3e50b4;
    padding-right: 5px;
    line-height: 36px;
    height: 36px;
    vertical-align: bottom;
}
.ad-scan-section i.fa-chevron-up {
    font-size: 20px;
    color: #494a4e;
    line-height: 20px;
    height: 20px;
    padding-right: 5px;
}

.ad-scan-section .ad-scan-section-title i {
    padding-right: 10px;
}
.ad-scan-section .span.hide-for-small-only {
    vertical-align: middle;
}
.ad-scan-section .ad-scan-section-title { 
    padding-right: 10px;
    text-transform: uppercase;
}
.ad-scans-content ul{
  list-style-type: none;
}

.ad-scans-content ul a{
  font-size: 20px;
  color: #495d89;
  outline: none;
}

.ad-scan-collapsed {
    display: none;
}
.ad-scan-expanded {
    display: block;
}
.ad-scan-section span.separator {
    color:#3e50b4;
}

.ad-scans .column-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
}
.ad-scans .img-merchant-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    padding: 3px 3px;
    box-shadow: none;
}
.ad-scans .merchant-img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: 100% !important;
}

@media screen and (min-width: 685px) {
    .ad-scan-section i.fa-caret-up,
    .ad-scan-section i.fa-caret-down {
        font-size: 26px;
        color: #3e50b4;
        line-height: 26px;
        height: 26px;
    }
    .ad-scan-section i.fa-chevron-up {
        color: #26336b;
        line-height: 20px;
        height: 20px;
    }
    .ad-scan-section {
        border-bottom: solid 4px #3e50b4;
        font-size: 22px;
    }
    .ad-scan-section.collapse-title {
        padding: 10px;
        line-height: 26px;
    }
    .ad-scan-section .ad-scan-section-title { 
        text-transform: none;
    }
    .ad-scans-subtitle {
        color: #53555b;
        font-size: 18px;
        padding-right: 5px;
        line-height: 22px;
        height: 22px;
    }
    .ad-scans .img-merchant-wrapper {
        height: 117px;
        width: 117px;
    }

}

@media screen and (min-width: 769px) and (max-width: 877px) {
    .ad-scans .img-merchant-wrapper {
        height: 100px;
        width: 100px;
    }
}
@media screen and (min-width: 878px) {
    .ad-scans .img-merchant-wrapper {
        height: 117px;
        width: 117px;
    }
}