.top-ad {
    border-bottom:1px solid #d1d6e1;
    height: 50px;
    background: #d8d8d8;
    margin-top: 11px;
}

.coming-soon-big {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    width: 280px;
    height: 240px;
}

.coming-soon-big-bkg {
    width: 0;
    height: 0;
    border-top: 240px solid #3e50b4;
    border-right: 280px solid transparent;
}

.coming-soon-big:before {
    position: absolute;
    content: 'COMING SOON';
    width: 135px;
    height: 72px;
    font-size: 38px;
    font-weight: bold;
    font-family: $font-family-open-sans;
    line-height: 0.95;
    text-align: left;
    color: #ffffff;
    left: 35px;
    top: 30px;
}

// .coming-soon {
//     margin-bottom: 20px;
// }

.coming-soon-small {
    position: relative;
}

.coming-soon-small:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    border-top: 65px solid #3e50b4;
    border-right: 87px solid transparent;
    z-index: 1;
}
.coming-soon-small:after {
    position: absolute;
    content: 'COMING SOON';
    font-family: $font-family-open-sans;
    width: 79px;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.95;
    text-align: left;
    color: #ffffff;
    top: 6px;
    left: 6px;
    z-index: 2;
}

.coming-soon-small img {
    opacity: 0.5;
}

.giveaway-default {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: bold;
    background-color: #fff;
    letter-spacing: 1.5px;
    padding: 27px 0;
    text-align: center;
    font-family: $font-family-open-sans;
}
.giveaway-default .logo {
    background-image:url(../../../../img/icon_HR.png);
    background-repeat:no-repeat;
    background-size:35px 35px;
    background-position:0;
    padding:0 0 0 39px;
    font-size: 25px;
}

@media screen and (min-width: 780px){
    .giveaway-default{padding:39px 0;}
}

@media screen and (max-width: 683px) {
    .giveaway-default{
        padding: 10px 0;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 684px) {
    .top-ad {
        height: 90px;
    }
}
@media screen and (min-width: 873px) {
    .top-ad {
        height: 128px;
        margin-top: 0;
        background: #fff;
    }
}

.quick-links {
    background: #fff;
    margin-bottom: 11px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 3px #3e50b4;
    min-height: 50px;
    padding: 5px 5px;
    line-height: 30px;
    font-family: $font-family-open-sans;
    text-transform: uppercase;

    .title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.7px;
        text-align: left;
        color: #4a4a4a;
        margin-left: 5px;

        .fa-chevron-right {
            float:right;
            font-size: 20px;
            vertical-align: middle;
            line-height: 30px;
        }
    }

    .quick-links-subtitle {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(0, 0, 0, 0.54);
    }

    ul {
        list-style-type: none;
    }

    svg {
        color: #3e50b4;
        font-size: 30px;
        padding-right: 18.3px;
    }

    .quick-links-icon {
        width: auto;
        float: left;
    }
    .quick-links-title {
        display: inline-block;
    }
}

.bar-title svg {
    color: #3e50b4;
    font-size: 30px;
    padding-right: 10px;
    line-height: 36px;
    vertical-align: middle;
}
.bar-title svg.back-to-top {
    font-size: 40px;
    color: #26336b;
    float: left;
    padding-left: 10px;
    line-height: 30px;
}
.img-merchant-wrapper {
    margin-bottom: 10px;
}


@media screen and (min-width: 684px) {
    .quick-links{
        border: none;
        border-bottom: 15px solid #3e50b4;
        text-align: left;
        height: 115px;
        padding: 12px 5px;
        .title {
            margin: 0;
            padding: 0;
            padding: 3px 5px 0 7px;
        }
        ul {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0px;
            text-align: left;
            color: #3e50b4;
            text-decoration: underline;
            padding:0;
            margin:0;


        }
        svg {
            padding-right: 11px;
            font-size: 35px;
            margin-bottom: 10px;
        }
        .quick-links-icon {
            width: auto;
            float: left;
        }
        .quick-links-title {
            display: block;
            font-size: 20px;
            line-height: 20px;
        }
        .quick-links-links{
            width: 48%;
            float: right;
            ul a {
                color: #3e50b4;
            }
        }
        .quick-links-subtitle {
            float: left;
            font-size: 11px;
        }



    }

}
@media screen and (min-width: 873px) {
    .quick-links{
        padding: 17px 27px;
        .quick-links-links{
            width: 55%;
            float: right
        }
    }

}

.ad-img {
/*    background-image: url("../../../img/bencat-version-2-0-alt2-img29250l.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    margin-bottom: 16px
}

@media screen and (min-width: 684px) {
    .ad-img {
        height: 115px;
    }
}
.column-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
}
.img-merchant-wrapper {
    box-shadow: 0 1px 6px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    padding: 3px 3px;
}
.merchant-img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: 100% !important;
}

.bar-title {
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-bottom: solid 4px #3e50b4;
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-family-open-sans;
    text-align: left;
    color: #3e50b4;
    margin: 0;
    line-height: 30px;
    margin-top: 30px;
}

.bar-link {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #605c5e;
}

.bar-to-top a {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.7px;
    text-align: center;
    color: #26336b;
    float: right;
    text-decoration: underline;
}

.show-more {
    background-color: #dfdfdf;
    widows: 100%;
    padding: 5px;
}
.table {
    background: #fff;
    padding: 0 5px;
}
.table-header {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.9px;
    text-align: left;
    color: #494a4e;
}

.img-goodies {
    height: 168px;
    width: 100%;
}

.img-featured {
    height: 144px;
    width: 100%;
}

.scroll {
    overflow-y: auto;
}

.bottom-bar {
    cursor: pointer;
    padding: 5px;
    background-color: #dfdfdf;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    color: #3e50b4;
    overflow: hidden;
    svg {
        color: #fff;
        font-size: 16px;
        padding: 0 10px;
    }

}
.bottom-bar-empty {
    padding: 17px;
    background-color: #dfdfdf;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 100%;
}

.table-content {
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    color: #494a4f;
    height: auto;
    padding: 5px;
    background: #fff;
}

.table-content div:first-child {
    color: black;
    font-weight: bold;
}
.table-content div:nth-child(3){
    font-size: 18px;
    font-weight: bold;
    font-stretch: condensed;
    letter-spacing: -0.9px;
    text-align: left;
    color: #ee6b00;
}
.table-content div:nth-child(4) {
    font-size: 14px;
    font-stretch: condensed;
    letter-spacing: 0.3px;
    text-align: left;
    color: #494a4e;
    text-decoration: line-through;
}
.table div.table-content:nth-child(odd) {
    border-radius: 7px;
    background-color: #eee;
}
.bar-link.active {
    border-bottom: 3px solid #3e50b4;
}
.top-bar-wrapper {
    margin: 20px;
}
.back-to-top-bar {
    cursor: pointer;
    position: relative;
    background-color: #26336b;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    text-align: center;
    color: #ffffff;
    padding: 6px;

    svg{
        padding: 0 10px;
    }
}

.widget-content {
    background: #fff;
    height: 300px;
}

.gift-guides-wrapper .widget-content{
    overflow: hidden;
}

.down {
    background-color: rgba(0, 0, 0, 0.54);
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
}


@media screen and (min-width: 684px) {
    .img-merchant-wrapper {
        height: 117px;
        width: 117px;
        padding: 0;
        margin: 6px 10px;
    }
    .merchant-img {
        padding: 0;
    }
    .small-12.medium-6.columns.padding {
        padding-left: 17px;
    }
}
@media screen and (min-width: 769px) and ( max-width: 870px) {
    .img-merchant-wrapper {
        height: 100px;
        width: 100px;
        padding: 10px;
        margin: 6px 10px;
    }
    .merchant-img {
        padding: 0;
    }
    .small-12.medium-6.columns.padding {
        padding-left: 17px;
    }
}

.ellipsis {text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
