
.off-canvas-menu {
    .title-bar {
        background-image: linear-gradient(to bottom, #616161, #3e3e3e);
        .branding-icon {
            margin: 0 auto;
            height: 33px;
            font-size: 15px;
            line-height: 14px;
            color: #fff;
            background-image: url(../../../../img/icon_HR.png);
            background-repeat: no-repeat;
            background-size: 35px 35px;
            background-position: 0;
            padding: 0 0 0 37px;
            span {
                font-weight: bold;
            }
        }
        .search-icon {
            float: right;
            font-size: 17px;
            cursor: pointer;
        }
    }
    
}
.off-canvas-menu-content {
    .top-menu {
        padding: 18px 50px;
        background: #5d6690;
        a {
            background: #f27a23;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            padding: 9px;
        }
    }
    a {
        font-family: Open Sans Condensed;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;        
        text-decoration: none;
        display: block;
        padding: 1rem 1rem;
        border-bottom: 0.5px solid #979797;
        color: #FFF;
        text-transform: capitalize;
        height: 50px;       
    }
    li:active {
        background-color: #8D8D8D;
        border: 0.5px solid #979797;
    }
    li:hover {
        background-color: #8D8D8D;
        border: 0.5px solid #979797;
    }
    li a.fullsite-link {
        color: #000;
        font-size: 15px;
    }
    
    .close {
        border-top: 0.5px solid #979797;
        i{
            width: 20px;
            height:20px;
            background-image: url(../../../../img/close.png);
            background-size: contain;
            float: right;            
        }
    }
}

#search-form {
    margin: 0;
    padding: 0;
    position: fixed;
    background: #666666;
    border: 1px solid #979797;
    display: none;
    width:100%;
    height:50px;
    z-index:99999;
    top:0;
    input {
        padding: 0;
        margin: 0;
        font-size: 16px;
        padding: 10px;
        height:50px;
        cursor:pointer;
    }
    .close-search-icon{
            width: 20px;
            height:20px;
            background-image: url(../../../../img/close-black.png);
            background-size: contain;
            float: right;            
        }    
}

.submit-search {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 15px;
    color: #5d6690;
    font-size: 20px;
}