.paginator-two {
    margin:10px 0 38px 0;
}
.paginator-two .clearfix {
    margin-left: 0;
}
.paginator-two ul {
    list-style:none;
}
.paginator-two li {
    color:#495d89;
    float:left;
    font:600 14px/27px 'Open Sans', sans-serif;
    margin:0 3px;
}

.paginator-two li:first-child {
    margin-left:0;
}
.paginator-two li:last-child {
    margin-right:0;
}



.paginator-two .current {
    background:#cedded;
    border-radius:2px;
    font:600 14px/27px 'Open Sans', sans-serif;
    padding:0 9px;
    text-shadow:0 2px 4px rgba(0,0,0,0.16);
}

.paginator-two .first {}
.paginator-two .first a {
    text-transform:uppercase;
}

.paginator-two .previous a,
.paginator-two .next a {
    background:#495d89;
    border-radius:2px;
    box-shadow:0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
    color:#fff;
    font:600 14px/27px 'Open Sans', sans-serif;
    text-transform:uppercase;
    letter-spacing:0.5px;
}

.paginator-two .previous a:hover,
.paginator-two .next a:hover {
    background:#3e50b4;
    text-decoration:none;
    transition:color 0.5s ease;
}
.paginator-two .previous a:active,
.paginator-two .next a:active {
    background:#3d4e74;
    box-shadow:0 0 8px 0 rgba(0,0,0,0.12), 0 8px 8px 0 rgba(0,0,0,0.24);
}

.paginator-two .last {
    float:right;
    position:relative;
    right:-2px;
}
.paginator-two .last a {
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTFGNDI4MkZDRENEMTFFNEFENkZBMTA5QzFGN0E0QzAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTFGNDI4MzBDRENEMTFFNEFENkZBMTA5QzFGN0E0QzAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFMUY0MjgyRENEQ0QxMUU0QUQ2RkExMDlDMUY3QTRDMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFMUY0MjgyRUNEQ0QxMUU0QUQ2RkExMDlDMUY3QTRDMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqFwsugAAADSSURBVHjaYmDAAryieuRBGJscM7qAb1yH3F/Gf6n/mf4ZaRq43L11cc9HnBo8oztl/jExpjIxMnMwMjKx/GNg0FPVcb195/KeTxga/JI6pf79/5/GyMjMCRNjZGBkYWD8p6dl4nrr5vk9n+Ea3GNaJRn+MqUzMjFzoTsRaBPrvz//9ZT0HG/evbTvCwsDQwMTEyOb5j+mf+cYGP4xMP1nskHW8I/x3xGQsUz/2DSBal8yYoRQXHcXMn/botIyZD4TA4lgOGhgwRD5/+89Pg0AAQYA6AI9s4Ks6pcAAAAASUVORK5CYII=) no-repeat right center;
    color:rgba(73, 93, 137, 0.54);
    text-transform:uppercase;
    padding:0 16px 0 9px;
}
.paginator-two .last a:hover {
    background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0FBRjc0NzdDRENGMTFFNEJEN0ZDOTMwMzMzOTA4QUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0FBRjc0NzhDRENGMTFFNEJEN0ZDOTMwMzMzOTA4QUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozQUFGNzQ3NUNEQ0YxMUU0QkQ3RkM5MzAzMzM5MDhBQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozQUFGNzQ3NkNEQ0YxMUU0QkQ3RkM5MzAzMzM5MDhBQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsgpceYAAACzSURBVHjaYmDAAjwi2yxBGJscI7qAe1SnGSPT/90g9v9/jK47l5WfwqnBLardmJmZaQ+QKQAV+vD37z+XXcsqz8LUMMEYrlGtBkzMTLuQFIOAAEgMJIdig0t0hy4rI+M+IE8Em7sZ/jO8+f3/v9OepRWXWRgYGphYmBh9/jH8XwKxkrEAWS1QfALIWBZGRh+g2qsYnvaM7fyPzN++uBxFDRMDiWA4aGDBCPL/DPfxaQAIMACgYTEeRnntkQAAAABJRU5ErkJggg==) no-repeat right center;
    color:#495d89;
}


.paginator-two a {
    color:#495d89;
    border-radius:2px;
    display:block;
    font:600 14px/27px 'Open Sans', sans-serif;
    padding:0 9px;
}
.paginator-two a:hover {
    background:#cedded;
    text-decoration:none;
}

.paginator-two .previous a:before {
    content: '<';
}

.paginator-two .next a:before {
    content: '>';
}

@media screen and #{breakpoint(large)} {
    .paginator-two .previous a:before {
        content: 'PREVIOUS';
    }
    
    .paginator-two .next a:before {
        content: 'NEXT';
    }
}