.page410 .main-section{
  background: #000;
}

.error-container .e410-c1 {
  text-align: center;
  @media screen and (max-width: 39.9375em) {
    margin-bottom: 30px;
  }
}

.error-container .e410-c1 {
  text-align: center;
  @media screen and (max-width: 39.9375em) {
    margin-bottom: 30px;
  }
}

.error-container .e410-c2 hr {
  border: 1px solid #2b537b;
  margin: 80px 0px;
  @media screen and (max-width: 39.9375em) {
    margin: 50px 0px;
  }
}

.page410 footer {
  margin-top: 0 !important;
}
