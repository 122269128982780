@import '../../mixins/_mixins.scss';

.offer-codes-widget {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 20px;
    width: 100%;
}

.codes-list {
    display: flex;
    flex-direction: column;
}

.active-coupon {
    border-radius: 2px;
    display: flex;
    padding: 12px 10px;
    .left-side {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 1.21;
        .offer-title {
            order: 1;
            font-size: 14px;
            font-weight: bold;
        }
        .offer-description{
            order: 3;
        }
        .expires{
            order: 1;
        }
    }
    .right-side {
        flex: 1;
        font-size: 14px;
        max-width: 135px;
        .code{
            border-left: dashed #ff9700 1.5px;
            border-right: dashed #ff9700 1.5px;
            border-top: dashed #ff9700 1.5px;
            color: #3f51b5;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 12px;
            padding: 8px 15px;
            text-align: center;
            width: 135px;
            word-wrap: break-word;
        }
        .code-button {
            @include code-button;
            .code-button-link {
                color: #fff;
            }
        }
    }
}

.active-coupon:nth-child(odd) {
    background: #f5f6fa;
}
.active-coupon:nth-child(even) {
    background: #fff;
}

@media screen and #{breakpoint(large)} {
    .active-coupon .right-side {
        max-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .active-coupon .right-side .code {
        display: inline-block;
    }

    .active-coupon .right-side .code{
        border: dashed 3px #ff9700;
        border-right: none;
        font-size: 16px;
        height: auto;
        line-height: 14px;
        width: 160px;
    }
}