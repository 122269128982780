.top-ad-container {
    width: 100%;
    .top-bar-ad {
        margin: 0 auto;
        height: 50px;
        width: 320px;
    }
}
@media screen and #{breakpoint(medium)} {
    .top-ad-container {
        background-color: #fff;
        height: 102px;
        .top-bar-ad {
            height: 50px;
            height: 93px;
            margin: 0 auto;
            max-width: 701px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }
}

@media screen and #{breakpoint(large)} {
    .main-banner {
        background: #fff;
        width: 100%;
        height: 118px;
        .main-banner-inner {
            align-items: center;
            display: flex;
            height: 100%;
            margin: 0 auto;
            max-width: 951px;
            width: 100%;
            .logo {
                flex: 1;
                height: 88px;
                width: 223px;
                a {
                    display: block;
                    height: 100%;
                }
            }
            .top-ad-container {
                flex: 3;
                height: 100%;
                .top-bar-ad {
                    margin: 0 auto;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    #branding {
    border-bottom:1px solid #d1d6e1;
    margin:0;
}
.pageHubPagesResponsive  #branding,.pageHubPagesResponsive .special-event{border-bottom: none;} 
.pageHubPagesResponsive #branding + .pageHubPagesResponsive .main-section .content-container{padding-top: 0px!important;}
.special-event {
    background-color: #eee;
}
.special-event a{
    display: inline-block;
    width:100%;
}
.special-event-title {
    background: #B20103;
    color: #fff;
    display: inline-block;
    font: bold 14px/26px 'Open Sans', sans-serif;
    margin: 0 10px 0 0;
    padding: 0 18px;
    position: relative;
    vertical-align: baseline;
}

.special-event-title:before {
    background: url(/img/right-arrow.jpg) no-repeat;
    content: "";
    height: 26px;
    position: absolute;
    right: 0;
    top: 0;
    width: 12px;
    background-color: #eee;
    background-blend-mode: multiply;
}

.special-event-title:after {
    background: url(/img/left-arrow.jpg) no-repeat;
    content: "";
    height: 26px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
    background-color: #eee;
    background-blend-mode: multiply;
}

.special-event-subtitle{
    color: #444;
    display: inline-block;
    float: none;
    font: bold 12px/37px 'Open Sans', sans-serif;
    text-decoration: underline;
    vertical-align: baseline;
}

.special-event-wrapp {
    margin: 0 auto;
    position: relative;
    width: 951px;
    background: url(/img/bens-robot.png) right no-repeat;
}
.prime-event-wrapp {
    margin: 0 auto;
    position: relative;
    width: 951px;
    background: url(/img/prime-day-bot-date.png) right no-repeat;
}
}
@media screen and (max-width: 63.99875em) {
    .prime-event-wrapp {
	display:none;
    }
}
