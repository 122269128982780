@import '../widgets/merchant-page/_top-bar.scss';
@import '../layout/breadcrumbs';
/** main content **/
@import '../widgets/merchant-page/offer-codes';
@import '../widgets/merchant-page/offers';
@import '../widgets/merchant-page/other-popular-stores';
@import '../widgets/merchant-page/seo-box';

/** sidebar **/
@import '../widgets/merchant-page/merchant-info';
@import '../widgets/merchant-page/ads';
@import '../widgets/merchant-page/blog';
@import '../widgets/merchant-page/latest-deals';
@import '../widgets/merchant-page/sticky-ad';

.grid-container{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 25px;
}
body{
    background-color: #eeeeee;
}

.main-content {
    grid-column: 1 / 13;
    grid-column-start: 1;
    -ms-grid-column-span: 13;
}

.main-content > div,
.sidebar > div{
    margin-bottom: 20px;
}

.sidebar{
    display: none;
}
.sidebar-login-signup {
    color: #444444;
    font: normal 14px/14px "Open Sans", sans-serif;
    text-align: center;
    .sidebar-login,
    .sidebar-sign-up{
        color: #363ea3;
        font-weight: bold;
    }
}

.white-blue-titlebar {
    background-color: #fff;
    border-bottom: solid #4448b7 4px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.40),0 0 2px 0 rgba(0,0,0,0.12);
    display: flex;
    height: 52px;
    position: relative;
    .white-blue-title {
        color: #3e50b4;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.9px;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 60px 0 20px;
        width: 100%;
    }
    .angle-up {
        align-items: center;
        display: flex;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        i{
            color: #494a4e;
            font-size: 30px;
            text-decoration: none;
        }
    }
    // .back-to-top-text{
    //     display: none;
    // }
}

@media screen and #{breakpoint(medium)} {
    .main-content {
        grid-column: 1 / 9;
        grid-column-start: 1;
        -ms-grid-column-span: 9;
        padding-right: 15%;
        @supports (grid-gap: 25px){
            padding-right: 0;
        }
    }

    .sidebar{
        display: block;
        grid-column: 9 / 13;
        grid-column-start: 9;
        -ms-grid-column-span: 13;
        .sidebar-login-signup {
            line-height: 20px;
        }
    }
}

@media screen and #{breakpoint(large)} {
    .white-blue-titlebar .white-blue-title {
        font-size: 22px;
    }

    .white-blue-titlebar .back-to-top {
        color: #26336b;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.7px;
        text-align: center;
        text-decoration: underline;
        padding-left: 10px;
    }

    .sidebar {
        display: flex;
        flex-direction: column;     
        .merchant-info {
            order: 1;
        }
        .ad-slot-2 {
            order: 2;
        }
        .sidebar-login-signup {
            order: 3;
        }
        .the-checkout {
            order: 4;
        }
        .ad-slot-1 {
            order: 5;
        }
        .latest-deals {
            order: 6;
        }
        #stickyad-wrapp {
            order: 7;
        }
    }
}
@media screen and (max-width: 689px){
    .ad-slot-2 .app-buttons .buttons-wrapper .img-play-store, .ad-slot-2 .app-buttons .buttons-wrapper .img-app-store{
        width: 97px;
    }
    .ad-slot-3{
        width: 100%;
        overflow: hidden;
    }
}