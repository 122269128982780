@import '../mixins/_mixins.scss';
article.flag-offer_status-selling_out .offer-badge{
    background-color: #d4591c;
    color: #fff;
    &:before {
        bottom: -5px;
        left: 0;
        @include css-triangle(#000, up-right, 3px, absolute, false);
        @include breakpoint(large){
            left: -1px;
        }
    }
    &:after {
        content: "Selling Out!";
        position: absolute;
        top: 0;
        left: 9px;
    }
    .ribbon-tails{
        height: 0;
        width: 0;
        &:after {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#a34110, up-right, 6px, absolute, false);
        }
        &:before {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#a34110, down-right, 6px, absolute, false);
        }
    }
}

article.flag-offer_status-holiday_gift .offer-badge {
  
    background-color: #afcded;
    color: #333;
    &:before {
        bottom: -5px;
        left: 0;
        @include css-triangle(#000, up-right, 3px, absolute, false);
        @include breakpoint(large){
            left: -1px;
        }
    }
    &:after {
        content: "Gift Idea";
        position: absolute;
        top: 0;
        left: 11px;
    }
    .ribbon-tails{
        height: 0;
        width: 0;
        &:after {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#95b2d1, up-right, 6px, absolute, false);
        }
        &:before {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#95b2d1, down-right, 6px, absolute, false);
        }
    }
}

article.flag-award-silver .offer-badge,
article.flag-award-gold .offer-badge {
    background-color: #2300be;
    color: #fff;
    &:before {
        bottom: -5px;
        left: 0;
        @include css-triangle(#000, up-right, 3px, absolute, false);
        @include breakpoint(large){
            left: -1px;
        }
    }
    &:after {
        content: "Ben\'s Best";
        position: absolute;
        top: 0;
        left: 15px;
    }
    .ribbon-tails{
        height: 0;
        width: 0;
        &:after {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#1e00a1, up-right, 6px, absolute, false);
        }
        &:before {
            bottom: -5px;
            left: -7px;
            z-index: -1;
            @include css-triangle(#1e00a1, down-right, 6px, absolute, false);
        }
    }
}

@media screen and #{breakpoint(large)} {
    article.flag-offer_status-selling_out .offer-badge{
        .ribbon-tails{
            &:after {
                @include css-triangle(#a34110, up-right, 8px, absolute, false);
            }
            &:before {
                @include css-triangle(#a34110, down-right, 8px, absolute, false);
            }
        }
    }

    article.flag-offer_status-holiday_gift .offer-badge {
        .ribbon-tails{
            &:after {
                @include css-triangle(#95b2d1, up-right, 8px, absolute, false);
            }
            &:before {
                @include css-triangle(#95b2d1, down-right, 8px, absolute, false);
            }
        }
    }

    article.flag-award-silver .offer-badge,
    article.flag-award-gold .offer-badge {
        .ribbon-tails{
            &:after {
                @include css-triangle(#1e00a1, up-right, 8px, absolute, false);
            }
            &:before {
                @include css-triangle(#1e00a1, down-right, 8px, absolute, false);
            }
        }
    }
}
