/* GLOBAL BREAKPOINTS */
@mixin for-small-phone-only {
  @media (max-width: 359px) { @content; }
}
@mixin for-phone-only {
  @media (max-width: 424px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 425px) { @content; }
}
@mixin for-tablet-portait-only {
  @media (min-width: 425px) and (max-width: 767px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 768px) { @content; }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 768px) and (max-width: 1023px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1024px) { @content; }
}
@mixin for-desktop-only {
  @media (min-width: 1024px) and (max-width: 1224px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1225px) { @content; }
}

/** CUSTOM BREAKPOINTS FOR SOME ELEMENTS **/
@mixin footer-big {
  @media (min-width: 640px) { @content; }
}

@mixin footer-big-landscape-only {
  @media (min-width: 640px) and (max-width: 767px) { @content; }
}
