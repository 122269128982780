.page404 .main-section{
  background: #000;

}

.error-container{
  margin-top:60px;
  color: #fff;
  padding-bottom: 30px;
}
.error-container .e404-c1{
  text-align: center;
  @media screen and (max-width: 39.9375em) {
    margin-bottom: 30px;
  }

}
.error-container .e404-c2 hr{
  margin-top: 50px;
  border: 1px solid #2b537b;
  margin: 80px 0px;
  @media screen and (max-width: 39.9375em) {
    margin: 50px 0px;
  }
}
.error-container h2 {
  font-size: 2.0em;
}

.error-container h3 {
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #404040;
}
.error-container img{
  width: 100%;
  margin-bottom: 20px;
}

.error-page-btn{
  color: #fff;
  display: inline-block;;
  text-align: center;
  border: 3px solid #2da152;
  padding: 10px 25px;
  font-weight: bold;
  margin: 5px 0px;
  @media screen and (max-width: 39.9375em) {
    width: 100%;
  }
}

.error-page-btn:active,
.error-page-btn:hover
{
    color: #fff;
    border: 3px solid #fff;

}

.page404 footer
{
    margin-top: 0 !important;
}
