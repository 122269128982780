.promo_banner {
    display: flex;
    align-items: center;
    background: #FCFCFC;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    width: 100%;
    min-height: 50px;
    height: auto;
    z-index: 1;
    position: relative;
    text-decoration: none;
    min-width: 320px;
    &:hover { text-decoration: none; }
    @include for-tablet-landscape-up {
        justify-content: center;
    }
    .show-mobile {
        display: inline-block;
        @include for-tablet-landscape-up {
            display: none;
        }
    }
    .hide-mobile {
        display: none;
        @include for-tablet-landscape-up {
            display: inline-block;
        }
    }
}
.promo_banner_image {
    max-height: auto;
    object-fit: contain;
    margin: 0 10px;
    max-width: 25%;
    min-width: 25%;
    height: 30px;
    @include for-tablet-landscape-up {
        height: 36px;
        margin: 0 26px;
        min-width: auto;
    }
}
.promo_banner_description {}

.promo_banner_button {
    margin: 0 26px;
}

.promo_banner_description_text {
    display: flex;
    align-items: center;
    color: #505050;
    font-size: 16px;
    width: 220px;
    font-weight: 700;
    flex: 100%;
    @include for-tablet-landscape-up {
        width: auto;
        color: #181818;
        font-size: 18px;
        text-align: left;
    }
}
.promo_banner_description_button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4A5BB9;
    font-size: 12px;
    text-align: left;
    width: auto;
    min-width: 168px;
    text-transform: uppercase;
    font-weight: 700;
    &:after {
        content: '>';
        padding: 0 5px;
        font-size: 15px;
        font-weight: 700;
    }
    @include for-tablet-landscape-up {
        background: #4A5BB9;
        border-radius: 4px;
        height: 30px;
        color: #FFF;
        font-size: 13px;
        padding: 0 10px;
        text-align: center;
        &:after {
            content: none;
        }
    }
}

.promo_banner.danube {
    background: #fff;
    .promo_banner_description_text {color: #2D2D2D;}
    .promo_banner_description_button {
        color: #4A5BB9;
        @include for-tablet-landscape-up {background: linear-gradient(#314093, #4A5BB9);color: #fff;}
    }
}
.promo_banner.unrhymed {
    background: #fff;
    .promo_banner_description_text {color: #2D2D2D;}
    .promo_banner_description_button {
        color: #D45100;
        @include for-tablet-landscape-up {background: linear-gradient(#D94701, #EE710C); color: #fff;}
    }
}
.promo_banner.hard {
    background: #fff;
    .promo_banner_description_text {color: #2D2D2D;}
    .promo_banner_description_button {
        color: #318D0D;
        @include for-tablet-landscape-up { background: linear-gradient(#38A119, #6BB817); color: #fff;}
    }
}
.promo_banner.oireland {
    background: #181818;
    .promo_banner_description_text {color: #fff;}
    .promo_banner_description_button {
        color: #FFD129;
        @include for-tablet-landscape-up {background: #FFD129; color: #000;}
    }
}
.promo_banner.steel {
    background: linear-gradient(to right, #1F2D7E, #4A5BB9);
    .promo_banner_description_text {color: #fff;}
    .promo_banner_description_button {
        color: #FFEC41;
        @include for-tablet-landscape-up {background: #FFD129; color: #000;}
    }
}
.promo_banner.pumpking {
    background: linear-gradient(to right, #B83C0B, #EE710C);
    .promo_banner_description_text {color: #fff;}
    .promo_banner_description_button {
        color: #FFEC41;
        @include for-tablet-landscape-up {background: #FFD129; color: #000;}
    }
}
.promo_banner.wazowski {
    background: linear-gradient(to right,#2F6D1D, #6BB817);
    .promo_banner_description_text {color: #fff;}
    .promo_banner_description_button {
        color: #FFEC41;
        @include for-tablet-landscape-up {background: #FFD129; color: #000;}
    }
}

/* .banner-style. */