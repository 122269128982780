/* offer's code's button mixin it is used on the dealbox and offer's code widget */
@mixin code-button {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: #ee6b00;
    border: solid 0.5px transparent;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    font-weight: bold !important;
    justify-content: center;
    letter-spacing: 0.2px;
    line-height: 12px;
    padding: 5px;
    text-align: center;
    width: 135px;
    cursor: pointer;
    @include breakpoint(large) {
        display: inline-block;
        height: 41px;
        width: 130px;
    }
    .long-copy{
        display: none;
        @include breakpoint(large) {
            display: block;
            font-size: 10px;
        }
    }
    .short-copy{
        @include breakpoint(large) {
            display: none;
        }
    }
}

/* When using ::before and ::after you'll always need these three, so we're saving two lines of code every time you use this.  */
@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

/** mixin for drawing triangles it can be used for example on the ribbons ***/
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    } @else if  $direction == up-right {
        border-top: $size solid $color;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        border-left: $size solid transparent;
    } @else if  $direction == down-right {
        border-top: $size solid transparent;
        border-bottom: $size solid $color;
        border-right: $size solid $color;
        border-left: $size solid transparent;
    }
}