.ad-slot-1 {
    height: 257px;
}
.ad-slot-2 {
    height: auto;
    .bens-app-title-ad {
        color: #3f51b5;
        font: 700 18px/28px "Open Sans", sans-serif !important;
        text-align: center;
        @include breakpoint(medium) {
            text-align: left;
        }
    }
    .app-buttons {
        display: flex;
        justify-content: center;
        min-height: 89px;
        .bens-logo {
            min-height: 89px;
            min-width: 89px;
        }
        .buttons-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 10px;
            .img-play-store,
            .img-app-store {
                outline: none;
                display: block;
                width: 117px;
                max-height: 43px;
            }
        }
    }
}

.ad-slot-3 {
    height: 605px;
}

@media screen and #{breakpoint(medium)} {
    .ad-slot-2 {
        .app-buttons {
            justify-content: flex-start;
        }
    }
}