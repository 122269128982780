.other-popular-stores-widget {
    height: auto;
    margin-bottom: 20px;
    .ops-container{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .ops-item-wrapper{
            width: 33.3333%;
            display: flex;
            justify-content: center;
            .ops-item{
                flex: 0 0 100px;
                background-color: #fff;
                height: auto;
                width: 100px;
                margin: 3px;
                .featured-merchant-link {
                    align-items: center;
                    display: flex;
                    height: 100px;
                    max-width: 100px;
                    max-height: 70px;                    
                    text-indent:-9999px;
                    background-repeat: no-repeat;
                    background-position: center;
                    border:#cccccc 1px solid;
                    background-size: 76px auto;                      
                    .featured-merchant-img {
                        border: none;
                        max-width: 100px;
                    }
                }
            }
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .other-popular-stores-widget .ops-container .ops-item-wrapper{
        width: 25%;
        margin-bottom: 5px;
    }

    .other-popular-stores-widget .ops-container .ops-item-wrapper{
        .ops-item{
            .featured-merchant-link {
                max-width: 100px;
                max-height: 70px;    
                .featured-merchant-img {
                    max-width: 100px;
                }
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    .other-popular-stores-widget .ops-container .ops-item-wrapper{
        width: 20%;
        .ops-item{
            flex: 0 0 117px;
            background-color: #fff;
            height: auto;
            width: auto;
            .featured-merchant-link {
                max-width: 117px;
                max-height: 83px;                    
                .featured-merchant-img {
                    max-width: 117px;
                }
            }
        }
    }
}
