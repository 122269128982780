.featured-articles-wrapper {
    background: #fff;
    padding: 10px 5px;
    margin-top: 30px;
    .title {
        font-size: 32px;
        font-weight: bold;
        font-family: $font-family-open-sans;
        line-height: 1.06;
        text-align: left;
        color: #4a4a4a;
        padding: 10px;
    }
    .featured-article {
        height: 100%;
        padding: 10px 0;
    }
    .img-featured-articles {
        width: 145px;
        height: 152px;
        display: inline-block;
    }
    .img-description {
        font-family: $font-family-open-sans;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        color: #000000;
        float: right;
        width: 48%;
        line-height: 23px;

        a {
            color: #000 !important;
        }
    }
}

@media screen and (min-width: 684px) {
}
@media screen and (min-width: 873px) {
    .featured-articles-wrapper {
        .img-featured-articles {
            width: 205px;
            height: 210px;
        }
        .img-description {
            text-align: left;
            float: none;
            width: 85%;
            padding-top: 10px;
        }
        .title {
            padding: 10px 15px;
        }
    }
}