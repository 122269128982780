.widget-header {
    align-items: center;
    background: linear-gradient(to bottom, #90a1c6 0%,#6178ad 3%,#576a96 51%,#435889 54%,#3b4f7b 83%,#3c507c 91%,#3e5380 94%,#465d90 100%);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border: none;
    display: flex;
    height: auto;
    min-height: 40px;
    text-transform: capitalize;
    width: 100%;
    .widget-title {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 0 !important;
        padding: 5px 12px;
        line-height: 30px;
    }
}